import React from 'react';
import { TestCaseStatus } from '../../../store/testCase';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import { green, red, grey } from '@mui/material/colors';
import { motion } from 'framer-motion';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    backgroundColor: grey[500],
    position: 'relative',
    width: '20px',
    height: '10px',
    borderRadius: '34px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
  },
  buttonFail: {
    backgroundColor: red[500],
  },
  dot: {
    backgroundColor: grey[300],
    height: '5px',
    width: '6px',
    position: 'absolute',
    left: '11px',
    top: '2px',
    borderRadius: '50%',
  },
  dotSuccess: {
    color: '#ffff',
  },
  dotFail: {
    left: '3px',
    color: '#ffff',
  },
}));

type TestCaseStatusIcon = {
  status?: TestCaseStatus;
};

const TestCaseStatusIcon: React.FC<TestCaseStatusIcon> = ({ status }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {status === TestCaseStatus.RUNNING ? (
        <CircularProgress
          color="secondary"
          size=".95rem"
          thickness={6}
          style={{ display: 'inline-block', margin: '0 3px 0 2px' }}
        />
      ) : (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0.4 }}
          className={classNames(classes.button, {
            [classes.buttonSuccess]: status === TestCaseStatus.SUCCESS,
            [classes.buttonFail]: status === TestCaseStatus.FAIL,
          })}
        >
          <div
            className={classNames(classes.dot, {
              [classes.dotSuccess]: status === TestCaseStatus.SUCCESS,
              [classes.dotSuccess]: status === TestCaseStatus.FAIL,
            })}
          />
        </motion.div>
      )}
    </div>
  );
};

export { TestCaseStatusIcon };
